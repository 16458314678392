export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/admin": [3],
		"/admin/satisfactory-versions": [4],
		"/admin/tag-manager": [6],
		"/admin/unapproved-mods": [7],
		"/admin/unapproved-versions": [5],
		"/api-docs": [17],
		"/community": [28],
		"/content-policy": [21],
		"/guides": [18],
		"/guide/[guideId]": [23],
		"/guide/[guideId]/edit": [24],
		"/help": [26],
		"/mods": [8],
		"/mod/[modId]": [10],
		"/mod/[modId]/edit": [11],
		"/mod/[modId]/new-version": [15],
		"/mod/[modId]/version/[versionId]": [12],
		"/mod/[modId]/version/[versionId]/edit": [14],
		"/mod/[modId]/version/[versionId]/view": [13],
		"/new-guide": [19],
		"/new-mod": [27],
		"/privacy-policy": [16],
		"/settings": [9],
		"/tools": [20],
		"/tos": [22],
		"/user/[userId]": [25]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';